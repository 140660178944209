import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: rgb(231, 233, 236);
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 2px;
`;

export const SideBySideContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 20px;
    overflow: hidden;
`;

export const BackButton = styled.button`
    align-self: flex-start;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ffffff;
    }
`;

// You may need to add these styles to your TranscriberInput and SubmissionCheck components
export const ComponentWrapper = styled.div`
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    overflow: auto;
`;
