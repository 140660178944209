import axios from "../includes/axios";

export const getCars = async () => {
    try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get("/cars?token=" + token);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const getJob = async (jobHash: string) => {
    try {
        const response = await axios.get(
            `/jobs?hash=${jobHash}&token=${localStorage.getItem(
                "access_token",
            )}`,
        );
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const updateProcessedData = async (job_hash: string, data: any) => {
    try {
        const response = await axios.post(
            `/cars/${job_hash}/update_processed_data`,
            data,
        );
        return response.data;
    } catch (error) {
        console.error("Error updating processed data:", error);
    }
};

export const updateTranscription = async (
    job_hash: string,
    index: number,
    newTranscription: string,
) => {
    try {
        const response = await axios.post(
            `/cars/${job_hash}/update_transcription`,
            {
                index,
                transcription: newTranscription,
            },
        );
        return response.data;
    } catch (error) {
        console.error("Error updating transcription:", error);
    }
};
